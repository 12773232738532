/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import InnerHTML from 'dangerously-set-html-content';
import Layout from '../components/Layout/index';
import SectionHeader from '../components/Common/SectionHeader';
import { Seo } from '../components/Seo';

const DonateWithCryptocurrency = () => {
  return (
    <Layout>
      <Seo
        title="Donate with Cryptocurrency | National Embryo Donation Center"
        description={
          'Yes, the NEDC now accepts charitable gifts via cryptocurrency! When you support the NEDC via cryptocurrency, you’re getting the tiniest lives “out of the freezer” and into loving homes. You’re giving hope to those who thought their dreams of parenthood would never come true. You’re CHANGING LIVES by helping children and building families. What more […]'
        }
      />
      <section>
        <div
          className="text-content"
          sx={{
            mx: 'auto',
            '& h4': {
              mb: '0.5rem',
              variant: 'text.lg',
            },
            p: {
              mb: '1rem',
              variant: 'text.normal',
            },
          }}
        >
          <SectionHeader
            heading={'Donate with Cryptocurrency'}
            styles={{ pt: [null, null, null, '3rem'] }}
          />
          <p>
            Yes, the NEDC now accepts charitable gifts via cryptocurrency! When you support the NEDC
            via cryptocurrency, you’re getting the tiniest lives “out of the freezer” and into
            loving homes. You’re giving hope to those who thought their dreams of parenthood would
            never come true. You’re CHANGING LIVES by helping children and building families. What
            more important work is there on the planet?
          </p>
          <p>
            Donating cryptocurrency is a non-taxable event, meaning you do not owe capital gains tax
            on the appreciated amount and can deduct it on your taxes. This makes Bitcoin and other
            cryptocurrency donations one of the most tax efficient ways to support your favorite
            cause. If you want to learn more about how donating crypto can lower your taxes, check
            out <a href="https://thegivingblock.com/faq">thegivingblock.com/faq.</a> Talk to a
            crypto-savvy tax professional or connect with The Giving Block to get connected with
            one.
          </p>
          <h4>We accept the following cryptocurrencies:</h4>
          <div
            sx={{
              mb: '1rem',
              pl: '2rem',
            }}
          >
            {/* <VscCircleFilled /> */}
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3C9rp14SZt51&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Bitcoin (BTC)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3K9F3Gf49KwFp04&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Ethereum (ETH)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3C65wmW3DH-MH4fGC1KW3T1McG4fLMww2223&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Basic Attention Token (BAT)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3z7zgk0&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Aave (AAVE)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3zd5Rdf3K6KSl04&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Alchemix (ALCX)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NF41Qw0cTPN31&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Amp (AMP)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3zdZtCW1GFxNz4mGpFm0&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Ankr Network (ANKR)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3zk629W1GCt9R3T1kkFW4r51pT3zgCMZ1V3&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Axie Infinity Shards (AXS)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3C64W1f41QtgF04&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Balancer (BAL)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3C653yW43W8-K3K9GnZW49MW8-43SlzP0&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Bancor Network Token (BNT)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3C65qKw3Cf8Jz8WL2&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              BarnBridge (BOND)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3C9rp1W43Rxn_3F4G0x0&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Bitcoin Cash (BCH)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3F7xMDn41VcWb1_3&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              ChainLink (LINK)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3Fbt72f43Xx5z04&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Compound (COMP)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3Fd-qF21V3&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Curve (CRV)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3Fd-qF21V3&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Dai (DAI)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3H4S0kW41YztG3ZSz5q0&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Decentraland (MANA)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3H905Bf3FbsP204&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Dogecoin (DOGE)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3K77prw41n_t59Jr2&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Enjin Coin (ENJ)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3M0h4C39CQ2&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Fantom (FTM)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3M3CJVf3FbsP204&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Filecoin (FIL)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3P0vpmW41SVbH43SNGm2263&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Gemini Dollar (GUSD)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3T1kq5W3Fdy8l3JF546w43X5KG9x32&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Injective Protocol (INJ)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3Y2-YtW49hbrQ4fQ4GM21_3&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Kyber Network (KNC)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3ZWWqXf3FbsP204&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Litecoin (LTC)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3ZWWCpf45Npdg04&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Livepeer (LPT)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3ZZnSbf49M8XD04&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Loopring (LRC)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3_R5lp2263&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              Maker (MKR)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3_VrMTW43W8-M49PHh_4S7231&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Mirror Protocol (MIR)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c3_XYJpW1GyZbF3CcXJmF3FcJSPmMxt1&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Moss Carbon Credit (MCO2)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c43WfKC38R12&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Orchid (OXT)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c45LM-yf3P4G2R04&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              PAX Gold (PAXG)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c45SzBV4SZ_p1&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Polygon (MATIC)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NF41Qw0cSZ6b1&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Ren (REN)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c4cLxdx21V3&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Skale (SKL)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c4cNc9-W3T4d-J4cNCCw21V3&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Somnium Space (CUBE)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c4cQhLH21-3&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Storj (STORJ)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c4cQKz4n3T3qXQ243&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              SushiSwap (SUSHI)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c4cSn16n3Q_2wL2d3&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Synthetix (SNX)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c4fGD4w2273&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Tezos (XTZ)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c4fHRFDn3P5VwP1Y3&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              The Graph (GRT)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c4fHRFDF4cGnDKY6LC1&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              The Sandbox (SAND)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c4cMnqcW4fHLMj43XXNwF45SBhwmM071&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Smooth Love Potion (SLP)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c4fGCnF348D2&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Terra (LUNA)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c4fGCnFf3zhVvHV3&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              TerraUSD (UST)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NF41Qw0cMyF_1&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              UMA (UMA)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c4hJVmS4TNKB1&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Uniswap (UNI)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c4rxjp-W41n_Lc41PG5N21V3&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Yearn.Finance (YFI)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c4tv0ZX21Y3&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              Zcash (ZEC)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2Nw41Qw0cbyP2&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              0x (ZRX)
            </a>
            ,
            <a href="https://t.sidekickopen90.com/s3t/c/5/f18dQhb0S7kF8cFtbMVfd4VL59hl3kW7_k2841CX6NGW35Qwvd56RlSLW7vZrg98P-NN3f197v5Y04?te=W3R5hFj4cm2zwW4fHRGJ3T4G6zW3N_dVS3F8JJDW43T4P63K9dcwW49JGhp1Lw3JLW45VGcS4hLz2NW41Qw0c1QvGbw21Y3&si=8000000018224317&pi=cde2a9c2-8d94-49d5-9a63-e784bd25965e">
              {' '}
              1inch (1INCH)
            </a>
            ,<a href="https://apecoin.com/about"> ApeCoin (APE)</a>,
            <a href="https://www.maple.finance/"> Maplefinance (MPL)</a>,
            <a href="https://www.liquity.org/"> Liquity (LQTY)</a>,
            <a href="https://www.liquity.org/"> Liquity USD (LUSD)</a>,
            <a href="https://rally.io/"> Rally (RLY)</a>,
            <a href="https://www.defipulse.com/blog/defi-pulse-index"> DeFi Pulse Index (DPI)</a>,
            <a href="https://lido.fi/"> Lido (LDO)</a>,
            <a href="https://raydium.io/"> Raydium (RAY) </a>,
            <a href="https://saber.so/"> Saber (SBR)</a>,
          </div>
          <InnerHTML
            html={`<script id="tgb-widget-script">
        !function(t,e,i,n,o,c,d,s){t.tgbWidgetOptions={id:o,domain:n},(d=e.createElement(i)).src=[n,"widget/script.js"].join(""),d.async=1,(s=e.getElementById(c)).parentNode.insertBefore(d,s)}(window,document,"script","https://tgbwidget.com/","200321256","tgb-widget-script"); </script>`}
            // sx={{ py: 4 }}
          />
        </div>
      </section>
    </Layout>
  );
};

export default DonateWithCryptocurrency;
